<template>
  <content class="blog-wrapper">
    <b-overlay
      :show="show"
      no-wrap
    />
    <div class="group-area mt-1">
      <h4>Rekomendasi Kegiatan</h4>
      <p>
        Berikut ini adalah kegiatan yang kami lakukan untuk pengembangan keterampilan kaum muda!<br/>Kaum muda silahkan bergabung dengan kegiatan ini. Harap diperhatikan, kegiatan ini bersifat GRATIS sehingga tidak akan ada pemungutan biaya.
      </p>
    </div>
    <b-row
      class="mb-1"
    >
      <b-col
      >
        <b-button
          variant="primary"
          v-if="$can('create', 'kegiatan')"
          @click="tambah"
          class="mr-1"
        >
          <span class="text-nowrap">Tambah Kegiatan</span>
        </b-button>
        <b-button
          variant="primary"
          @click="filterShow"
        >
          <feather-icon
            icon="SearchIcon"
          />
        </b-button>
      </b-col>
    </b-row>

    <b-row
      class="blog-list-wrapper mt-1 mb-1"
    >
      <b-col
        md="12"
      >
        <list-filter
          :proyekOptions="proyekOptions.options"
          :tagPublicOptions="tagPublicOptions.options"
          v-if="isFilterShow"
          @emitFilter="emitFilter"
        />
      </b-col>
    </b-row>

    <b-carousel
      id="carousel-1"
      :interval="10000"
      indicators
      img-height="1080"
      v-if="showSlider()"
    >
      <b-carousel-slide
        v-for="data in kegiatanList.data"
        :key="data.id"
      >
        <template #img>
          <meetup
            :dataMeetup="data"
            :onlyView="false"
          />
          <br/>
        </template>
      </b-carousel-slide>
    </b-carousel>

    <!--/ sidebar -->
    <b-row
      class="blog-list-wrapper"
      v-if="showAll()"
    >
      <b-col
        v-for="data in kegiatanList.data"
        :key="data.id"
        md="4"
      >
        <meetup
          :dataMeetup="data"
          :onlyView="false"
        />
      </b-col>
    </b-row>
    <b-row>
      <!-- pagination -->
      <b-col cols="12"
        v-if="rows"
      >
        <div class="my-2">
          <b-pagination
            v-model="currentPage"
            align="center"
            :total-rows="rows"
            :per-page="perPage"
            @change="handlePageChange"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-col>
    </b-row>
    <content-loader
      v-if="loaderShow"
    />
    <b-modal
      id="modal-no-animation"
      content-class="shadow"
      title="Disabled Animation"
      no-fade
      ok-only
      ok-title="Accept"
    >
      <b-card-text>
        Chocolate bar jelly dragée cupcake chocolate bar I love donut liquorice.
        Powder I love marzipan donut candy canes jelly-o.
        Dragée liquorice apple pie candy biscuit danish lemon drops sugar plum.
      </b-card-text>
      <b-alert
        show
        variant="success"
      >
        <div class="alert-body">
          Well done! You successfully read this important alert message.
        </div>
      </b-alert>
    </b-modal>
  </content>
</template>

<script>
import { isMobile } from 'mobile-device-detect'
import {
  BRow, BCol, BCard, BFormInput, BButton, BModal, VBModal, BCardText, BCardTitle, BMedia, BAvatar, BMediaAside, BMediaBody, BImg, BCardBody, BLink, BBadge, BFormGroup, BInputGroup, BInputGroupAppend, BPagination, BOverlay, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import {
  getAllKegiatan, getAllKegiatanByProyek,
} from '@/api/kegiatan'
import {
  getAllProyek,
} from '@/api/proyek'
import {
  getTag,
} from '@/connection-api/master'
import Meetup from './Meetup.vue'
import ListFilter from './ListFilter.vue'
import ContentLoader from '../../layouts/Loader.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BPagination,
    Meetup,
    BOverlay,
    ContentLoader,
    ListFilter,
    BModal,
    BCarousel,
    BCarouselSlide,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      role: '',
      show: false,
      search_query: '',
      blogList: [],
      projectList: [],
      courseList: [],
      kegiatanList: [],
      blogSidebar: {},
      currentPage: 1,
      perPage: 9,
      rows: 0,
      proyekId: null,
      /* eslint-disable global-require */
      defaultImage: require('@/assets/images/pages/default.png'),
      /* eslint-disable global-require */
      loaderShow: true,
      proyekOptions: {
        selected: 'id',
        options: [
          { value: null, label: 'Semua' },
        ],
      },
      isFilterShow: false,
      tagPublicOptions: {
        selected: 'id',
        options: [],
      },
      searchForm: {
        nama: '',
        private: {
          id: 3,
          label: 'Semua',
        },
        status: {
          id: 'aktif',
          label: 'Aktif',
        },
        only_me: false,
      },
      amres: localStorage.getItem('amres'),
      showAllData: true,
    }
  },
  created() {
    // this.$http.get('/blog/list/data').then(res => { this.blogList = res.data })
    // this.$http.get('/blog/list/project').then(res => { this.projectList = res.data })
    // this.$http.get('/blog/list/course').then(res => { this.courseList = res.data })
    // this.$http.get('/blog/list/data/sidebar').then(res => { this.blogSidebar = res.data })
  },
  async mounted() {
    if (isMobile) {
      this.showAllData = false
    }
    // if (this.amres === 'true') {
    //   // console.log
    // } else {
    //   this.showMsgBoxAmres()
    // }
    const tagPublic = await getTag(2)
    this.mappingSelectOption(tagPublic.data, this.tagPublicOptions.options)
    const response = await getAllProyek({ byme: true })
    this.mappingSelectOption(response.data.data, this.proyekOptions.options)
    if (this.$router.currentRoute.query.proyekId) {
      this.proyekId = this.$router.currentRoute.query.proyekId
    }
    try {
      this.show = true
      this.getDataPage()
      this.show = false
      // this.loaderShow = false
    } catch (error) {
      // console.log(error.response.data)
    }
  },
  methods: {
    showSlider() {
      return isMobile
    },
    showAll() {
      let returns = true
      if (!isMobile && this.showAllData === true) {
        returns = true
      } else {
        returns = false
      }
      return returns
    },
    async emitFilter(searchForm) {
      const response = await getAllKegiatan(this.currentPage, searchForm)
      this.kegiatanList = response.data
      this.kegiatanList.data.forEach((value, index) => {
        if (value.img) {
          this.kegiatanList.data[index].img = `${process.env.VUE_APP_API_URL}/kegiatan/download/${value.img}`
        } else {
          this.kegiatanList.data[index].img = this.defaultImage
        }
      })
      this.rows = response.data.total
      this.loaderShow = false
      this.searchForm = searchForm
    },
    filterShow() {
      if (this.isFilterShow === false) {
        this.isFilterShow = true
      } else {
        this.isFilterShow = false
      }
    },
    handlePageChange(value) {
      this.currentPage = value
      this.getDataPage()
    },
    async getDataPage() {
      let response
      if (this.proyekId === null) {
        response = await getAllKegiatan(this.currentPage, this.searchForm)
      } else {
        response = await getAllKegiatanByProyek(this.currentPage, this.proyekId)
      }
      this.kegiatanList = response.data
      this.kegiatanList.data.forEach((value, index) => {
        if (value.img) {
          this.kegiatanList.data[index].img = `${process.env.VUE_APP_API_URL}/kegiatan/download/${value.img}`
        } else {
          this.kegiatanList.data[index].img = this.defaultImage
        }
      })
      this.rows = response.data.total
      this.loaderShow = false
    },
    tambah() {
      this.$router.push('/kegiatan/tambah')
    },
    kFormatter,
    tagsColor(tag) {
      if (tag === 'Quote') return 'light-info'
      if (tag === 'Gaming') return 'light-danger'
      if (tag === 'Fashion') return 'light-primary'
      if (tag === 'Video') return 'light-warning'
      if (tag === 'Food') return 'light-success'
      return 'light-success'
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    showMsgBoxAmres() {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxOk('Mohon melengkapi Data Pengguna terlebih dahulu.', {
          title: 'Informasi Kelengkapan',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Tutup',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          this.boxTwo = value
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
