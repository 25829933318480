<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Pencarian Kegiatan
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Proyek"
            label-for="account-username"
          >
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              v-model="searchForm.proyek"
              :options="proyekOptions"
              class="w-100"
              multiple
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Nama Kegiatan"
            label-for="account-username"
          >
            <b-form-input
              v-model="searchForm.nama"
              placeholder="Nama Kegiatan"
              name="username"
              class="form-control"
            />
          </b-form-group>
        </b-col>
         <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Status Kegiatan"
            label-for="account-name"
          >
            <v-select
              id="statusList"
              v-model="searchForm.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOption"
              item-text="id"
              item-value="label"
            />
          </b-form-group>
        </b-col>
         <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Status Privasi"
            label-for="account-name"
          >
            <v-select
              id="statusPrivateList"
              v-model="searchForm.private"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusPrivateOption"
              item-text="id"
              item-value="label"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Tag"
            label-for="account-name"
          >
            <v-select
              id="tag"
              v-model="searchForm.tag_public"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tagPublicOptions"
              item-text="value"
              item-value="label"
              :multiple="true"
              :reduce="(val) => val.value"
            />
          </b-form-group>
        </b-col>
        <!-- <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Periode Awal Kegiatan"
          >
            <flat-pickr
              v-model="searchForm.tanggal_mulai"
              class="form-control"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Periode Akhir Kegiatan"
          >
            <flat-pickr
              v-model="searchForm.tanggal_akhir"
              class="form-control"
            />
          </b-form-group>
        </b-col> -->
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <b-form-group
            label="Tampilkan Kegiatan Saya"
          >
            <b-form-checkbox
              v-model="searchForm.only_me"
              :checked="false"
              switch
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-button
            variant="primary"
            @click="cariData"
            class="mr-1"
          >
            <span class="text-nowrap">Cari</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormGroup,
    BFormInput,
    // flatPickr,
    BFormCheckbox,
    BButton,
  },
  data() {
    return {
      searchForm: {
        nama: '',
        private: {
          id: 3,
          label: 'Semua',
        },
        status: {
          id: 'aktif',
          label: 'Aktif',
        },
        only_me: false,
      },
      statusPrivateOption: [
        {
          id: 3,
          label: 'Semua',
        },
        {
          id: 0,
          label: 'Publik',
        },
        {
          id: 1,
          label: 'Privat',
        },
        {
          id: 2,
          label: 'General',
        },
      ],
      statusOption: [
        {
          id: 'semua',
          label: 'Semua',
        },
        {
          id: 'aktif',
          label: 'Aktif',
        },
        {
          id: 'tidak_aktif',
          label: 'Tidak Aktif',
        },
      ],
    }
  },
  props: {
    proyekOptions: {
      type: Array,
      required: true,
    },
    tagPublicOptions: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.$emit('emitFilter', this.searchForm)
    // const d = new Date()
    // const tanggalAwal = d.setMonth(d.getMonth() - 6)
    // this.searchForm.tanggal_mulai = tanggalAwal
    // const dn = new Date()
    // const tanggalAkhir = dn.setMonth(dn.getMonth() + 3)
    // this.searchForm.tanggal_akhir = tanggalAkhir
  },
  methods: {
    cariData() {
      this.$emit('emitFilter', this.searchForm)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
